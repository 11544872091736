<template>
  <section class="placar" :class="{ oculto: storeGame.carregar }">
    <div class="conteudo">
      <div class="faixa um">
        <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
        <p>Partida grátis</p>
      </div>
      <div class="faixa dois">
        <div class="pessoa um">
          <div class="foto" :style="`background-image: url('${imagemProprio}')`"></div>
          <p>{{ jogadorProprio }}</p>
        </div>
        <div class="valores">
          <h3>{{ placarProprio }}</h3>
          <div class="separador"></div>
          <h3>{{ placarOponente }}</h3>
        </div>
        <div class="pessoa dois">
          <p>{{ jogadorOponente }}</p>
          <div class="foto" :style="`background-image: url('${imagemOponente}')`"></div>
        </div>
      </div>
      <div class="faixa tres">
        <p>Jogadores escolhendo...</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreGame, useStorePerfil } from '@stores'

const storeGame = useStoreGame()
const storePerfil = useStorePerfil()

const valoresResultados = computed(() => {
  const usuario1 = storeGame.salaCriada?.usuario1
  const usuarioAtual = storePerfil.usuario?._id

  const souUsuario1 = usuario1?.id === usuarioAtual

  const placarProprio = souUsuario1 ? storeGame.resultadoRodada?.placar?.usuario1 : storeGame.resultadoRodada?.placar?.usuario2

  const placarOponente = souUsuario1 ? storeGame.resultadoRodada?.placar?.usuario2 : storeGame.resultadoRodada?.placar?.usuario1

  return {
    resultadoProprio: placarProprio || 0,
    resultadoOponente: placarOponente || 0
  }
})

const valoresJogadores = computed(() => {
  const usuario1 = storeGame.salaCriada?.usuario1
  const usuario2 = storeGame.salaCriada?.usuario2
  const usuarioAtual = storePerfil.usuario?._id

  const proprio = usuario1?.id === usuarioAtual ? usuario1 : usuario2
  const oponente = usuario1?.id === usuarioAtual ? usuario2 : usuario1

  return {
    jogadorProprio: proprio?.nome || null,
    jogadorOponente: oponente?.nome || null,
    imagemProprio: proprio?.imagem || null,
    imagemOponente: oponente?.imagem || null
  }
})

const jogadorProprio = computed(() => valoresJogadores.value.jogadorProprio)
const jogadorOponente = computed(() => valoresJogadores.value.jogadorOponente)
const imagemProprio = computed(() => valoresJogadores.value.imagemProprio)
const imagemOponente = computed(() => valoresJogadores.value.imagemOponente)
const placarProprio = computed(() => valoresResultados.value.resultadoProprio)
const placarOponente = computed(() => valoresResultados.value.resultadoOponente)
</script>

<style scoped>
section.placar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: calc(50% - 5dvh);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  opacity: 1;
  visibility: visible;
  transition: all 0.6s;
}

section.placar.oculto {
  opacity: 0;
  visibility: hidden;
  left: 0%;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.faixa.um {
  background-color: var(--cor-escuro-2);
  padding: 5px 20px;
  justify-content: flex-start;
}

.faixa.dois {
  background-color: var(--cor-escuro-3);
  padding: 10px 20px;
  justify-content: space-between;
}

.faixa.tres {
  background-color: var(--cor-escuro-2);
  padding: 5px 20px;
  justify-content: flex-end;
}

.faixa {
  display: flex;
  align-items: center;
  width: 100%;
}

.faixa.um img {
  width: 100%;
  max-width: 10px;
  margin: 0 5px 0 0;
}

.faixa.um p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: #d58dd3;
}

.faixa.tres p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-ciano);
}

.faixa .valores {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
}

.faixa .valores .separador {
  width: 100px;
  height: 2px;
}

.faixa .valores h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.faixa .pessoa.um {
  justify-content: flex-start;
}

.faixa .pessoa.dois {
  justify-content: flex-end;
}

.faixa .pessoa {
  display: flex;
  align-items: center;
  width: 33.33%;
}

.faixa .pessoa .foto {
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--cor-cinza);
  background-image: url('https://arquivos.jokenplay.com/imagens/placeholder-perfil.png');
  background-size: cover;
  background-position: center;
}

.faixa .pessoa p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 5px;
}
</style>
