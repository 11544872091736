<template>
  <section class="vencedor" :class="{ mostrar: storeGame.batalhaFinalizada }">
    <div class="brush" :style="`background-image: url('${storeGame.resultadoBatalha?.imagem}')`"></div>
    <div class="foto" :style="`background-image: url('${storeGame.resultadoBatalha?.imagem}')`">
      <img src="https://arquivos.jokenplay.com/imagens/mao-pedra.png" class="pedra" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-tesoura.png" class="tesoura" />
    </div>
    <h3>{{ storeGame.resultadoBatalha?.nome }} venceu essa batalha!</h3>
    <div class="moedas">
      <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
      <p>Ganhou 9 moedas</p>
    </div>
    <div class="botoes">
      <button class="voltar" @click="irParaInicio()">
        <Svgs nome="seta" />
        Voltar ao início
      </button>
      <button class="jogar" @click="jogarNovamente()" v-if="storePerfil.usuario?.saldo?.liberado >= 5">
        <div class="icone">
          <Svgs nome="playsolto" />
        </div>
        <h4>JOGAR</h4>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useStoreGame, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeGame = useStoreGame()
const storePerfil = useStorePerfil()
const router = useRouter()

function irParaInicio() {
  resetarGame()
  router.push('/inicio')
}

function jogarNovamente() {
  resetarGame()
  router.push('/procurando')
}

function resetarGame() {
  storeGame.socket = null
  storeGame.idSala = ''
  storeGame.usuariosOnline = 0
  storeGame.salaCriada = null
  storeGame.resultadoRodada = null
  storeGame.resultadoBatalha = null
  storeGame.batalhaFinalizada = false
  storeGame.carregar = false
}
</script>

<style scoped>
section.vencedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100dvh;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: var(--cor-escuro-1);
  background-size: cover;
  transition: all 0.5s;
}

section.vencedor.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  animation: mostrarVencedor 4s linear;
}

@keyframes mostrarVencedor {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  80% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 40px 0 0 0;
  z-index: 2;
}

.brush {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  opacity: 0.2;
  filter: blur(50px);
  z-index: 1;
  animation: brush 30s linear infinite;
}

@keyframes brush {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.foto {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
  animation: foto 2s ease-in-out infinite;
}

@keyframes foto {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  50% {
    transform: scale(1.1);
    filter: brightness(130%);
  }
  100% {
    transform: scale(1);
    filter: brightness(100%);
  }
}

.moedas {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  z-index: 2;
}

.moedas img {
  width: 100%;
  max-width: 15px;
  margin: 0 5px 0 0;
}

.moedas p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-amarelo);
}

img.tesoura {
  position: absolute;
  width: 100%;
  max-width: 80px;
  top: -40px;
  left: -60px;
  animation: brilhando 1s linear infinite;
  animation-delay: 2s;
}

img.pedra {
  position: absolute;
  width: 100%;
  max-width: 70px;
  bottom: 10px;
  right: -70px;
  animation: brilhando 1s linear infinite;
}

.botoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0 0;
  z-index: 2;
}

.botoes button.voltar {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-escuro-3);
  padding: 20px 35px;
  border-radius: 50px;
  transition: all 0.3s;
}

.botoes button.voltar:hover {
  background-color: var(--cor-escuro-4);
}

.botoes button.voltar svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
  transform: rotate(180deg);
}

.botoes button.jogar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px 0 0;
  background-color: var(--cor-escuro-3);
  border-radius: 50px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.botoes button.jogar:hover {
  background-color: var(--cor-escuro-4);
  transform: scale(1.05);
}

.botoes button.jogar .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--cor-amarelo);
}

.botoes button.jogar .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.botoes button.jogar h4 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 0 0 0 15px;
}

@media screen and (max-width: 1000px) {
}
</style>
