import texto from './helperTexto'
import detectarClickForaDoElemento from './detectarClickForaDoElemento'
import detectarMobile from './detectarMobile'
import bloqueioBody from './bloqueioBody'
import serviceWorker from './serviceWorker'
import redirectBrowser from './redirectBrowser'
import token from './token'
import { autoRedimensionarTextArea as autoRedimensionarTextArea } from './diretiva-auto-redimensionar-textarea.js'
import { limitarCaracteres as limitarCaracteres } from './diretiva-limitar-caracteres.js'

export { autoRedimensionarTextArea, limitarCaracteres }
export const helperTexto = texto
export const helperMobile = detectarMobile
export const helperClicouFora = detectarClickForaDoElemento
export const helperBloqueioBody = bloqueioBody
export const helperServiceWorker = serviceWorker
export const helperRedirectBrowser = redirectBrowser
export const helperToken = token
