<template>
  <Modal nome="alterarSenha">
    <h3>Alterar Senha</h3>
    <p>Crie uma nova senha para sua conta</p>
    <div class="input">
      <label>Senha atual</label>
      <input type="password" spellcheck="false" placeholder="senha#123" autocomplete="current-password" v-model="state.senhaAtual" />
    </div>
    <div class="input">
      <label>Nova senha</label>
      <input type="password" spellcheck="false" placeholder="senha#123" autocomplete="new-password" v-model="state.senhaNova" />
    </div>
    <button @click="salvar()" :disabled="state.loading">
      <span></span>
      Salvar Senha
    </button>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const state = reactive({
  senhaAtual: '',
  senhaNova: '',
  loading: false
})

function validarDados() {
  let mensagem
  if (!state.senhaAtual === !state.senhaNova) {
    mensagem = 'Preencha os campos'
  } else if (state.senhaAtual === state.senhaNova) {
    mensagem = 'A nova senha não pode ser igual à senha atual'
  } else if (state.senhaNova?.length > 0 && state.senhaNova?.length < 6) {
    mensagem = 'A senha precisa ter no mínimo 6 caracteres'
  } else {
    return true
  }

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
}

async function salvar() {
  if (!validarDados()) return

  state.loading = true

  const payloadSenha = {
    senhaAtual: state.senhaAtual,
    senhaNova: state.senhaNova
  }

  if (state.senhaAtual && state.senhaNova) await storePerfil.alterarSenha(payloadSenha)

  await storePerfil.receberPerfilUsuario()

  state.loading = false
  storeModal.fecharModal('alterarSenha')
}

function limparCampos() {
  state.senhaAtual = ''
  state.senhaNova = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 30px 0;
}

.input {
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}

.input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-escuro-3);
  width: 100%;
  transition: all 0.3s;
}

.input input:focus {
  border: 2px solid var(--cor-azul);
}

.input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: -6px;
  left: 7px;
  background-color: var(--cor-escuro-1);
  padding: 0 10px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(0deg, #7e0171, #ff6a00);
  background: -webkit-linear-gradient(0deg, #7e0171, #ff6a00);
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

button:hover {
  filter: brightness(120%);
}

button:disabled {
  color: transparent;
  pointer-events: none;
}

button:disabled span {
  opacity: 1;
  visibility: visible;
}

button span {
  position: absolute;
  width: 15px;
  height: 15px;
  border: 3px solid var(--cor-branco);
  border-left: 3px solid transparent;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation: rodando 0.5s linear infinite;
  transition: all 0.3s;
}

@keyframes rodando {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
</style>
