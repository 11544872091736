<template>
  <section class="jogar">
    <!-- Com moedas -->
    <div class="moedas">
      <div class="imagem">
        <img src="https://arquivos.jokenplay.com/imagens/texto-1x1.png" class="logo" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-laranjas.png" class="moeda" />
      </div>
      <div class="texto">
        <p>Batalha 1x1</p>
        <h3>Jogue com Moedas</h3>
        <button @click="abrirModal('jogar')">
          <div class="icone">
            <Svgs nome="playsolto" />
          </div>
          <h4>5</h4>
          <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
        </button>
      </div>
    </div>
    <!-- Grátis -->
    <div class="moedas gratis">
      <div class="imagem">
        <img src="https://arquivos.jokenplay.com/imagens/texto-free.png" class="logo" />
        <img src="https://arquivos.jokenplay.com/imagens/xp-fundo.png" class="moeda" />
      </div>
      <div class="texto">
        <p>Batalha 1x1</p>
        <h3>Jogue grátis</h3>
        <button @click="abrirModal('free')">
          <div class="icone">
            <Svgs nome="playsolto" />
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()

function abrirModal(nome) {
  storeModal.abrirModal(nome)
}
</script>

<style scoped>
section.jogar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 130px 20px;
  width: 100%;
  position: relative;
  z-index: 4;
}

.moedas {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-2);
  padding: 20px;
  width: 100%;
  border-radius: 10px;
}

.moedas.gratis {
  width: calc(100% - 40px);
  bottom: -70px;
  margin: 20px 0 0 0;
  position: absolute;
}

.moedas .imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 130px;
  border-radius: 10px;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.moedas .imagem img.logo {
  width: 90px;
  position: absolute;
  z-index: 2;
  animation: brilhando 3s linear infinite;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

.moedas.gratis .imagem img.logo {
  width: 90px;
  animation: brilhando 3s linear infinite;
  animation-delay: 2s;
}

.moedas .imagem img.moeda {
  width: 130px;
  position: absolute;
  z-index: 1;
  animation: mexendo 10s linear infinite;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.moedas.gratis .imagem img.moeda {
  width: 160px;
  left: -25px;
  animation: mexendo 10s linear infinite;
  animation-delay: 2s;
}

.moedas .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 30px;
}

.moedas .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.moedas .texto h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 5px 0 15px 0;
}

.moedas.gratis .texto button {
  padding: 0;
}

.moedas .texto button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  background-color: var(--cor-escuro-2);
  border-radius: 50px;
  transition: all 0.3s;
}

.moedas .texto button:hover {
  background-color: var(--cor-escuro-3);
  transform: scale(1.05);
}

.moedas.gratis .texto button .icone {
  background-color: var(--cor-roxo);
}

.moedas .texto button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-amarelo);
}

.moedas .texto button .icone svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
}

.moedas .texto button h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 10px 0 15px;
}

.moedas .texto button img {
  width: 100%;
  max-width: 15px;
}
</style>
