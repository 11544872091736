<template>
  <section class="topo">
    <div class="imagem">
      <img src="https://arquivos.jokenplay.com/imagens/mao-pedra.png" class="papel" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-tesoura.png" class="pedra" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-papel.png" class="tesoura" />
      <img src="https://arquivos.jokenplay.com/imagens/brilho.png" class="brilho" />
    </div>
    <div class="texto">
      <div class="tag">
        <Svgs nome="dinheiro" />
        <p>Mínimo de 50 moedas</p>
      </div>
      <h2>Saque suas moedas da JokenPlay</h2>
    </div>
    <div class="sombra"></div>
    <div class="botoes">
      <div class="moedas" :class="{ pendente: storeSaque.pendente }">
        <button class="quantidade menos" @click="diminuirQuantidade()">
          <Svgs nome="menos" />
        </button>
        <div class="atual">
          <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
          <h3>{{ state.quantidade }}</h3>
        </div>
        <button class="quantidade mais" @click="aumentarQuantidade()">
          <Svgs nome="mais" />
        </button>
      </div>
      <button class="total pendente" v-if="storeSaque.pendente">
        <p>Você tem um saque em andamento, aguarde!</p>
      </button>
      <button class="total" v-else @click="sacar()">
        <p>Clique aqui para sacar R$ {{ state.quantidade }},00</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useStoreModal, useStorePerfil, useStoreSaque } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const storeSaque = useStoreSaque()

const state = reactive({
  quantidade: 50,
  liberado: false
})

function verificarSaldo() {
  if (storePerfil.usuario?.saldo?.liberado >= 50) {
    state.liberado = true
  } else {
    state.liberado = false
  }
}

function aumentarQuantidade() {
  if (state.quantidade < storePerfil.usuario?.saldo?.liberado) {
    state.quantidade += 1
  }
}

function diminuirQuantidade() {
  if (state.quantidade > 50) {
    state.quantidade -= 1
  }
}

function sacar() {
  storeModal.abrirModalEdicao('sacar', state.quantidade)
}

onMounted(() => {
  verificarSaldo()
})
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('https://arquivos.jokenplay.com/imagens/background-2.png');
  background-size: cover;
  background-position: center;
  margin: 60px 0 0 0;
  padding: 0 0 130px 0;
}

.imagem {
  width: 100px;
  min-width: 100px;
  height: 100px;
  position: relative;
  margin: 0 0 0 50px;
}

.imagem img {
  position: absolute;
}

.imagem img.pedra {
  width: 100%;
  max-width: 60px;
  top: -10px;
  left: 20px;
  transform: rotate(-40deg);
  z-index: 1;
  animation: pedra 10s linear infinite;
}

@keyframes pedra {
  0% {
    transform: scale(1) rotate(-40deg);
  }
  50% {
    transform: scale(1.05) rotate(-35deg);
  }
  100% {
    transform: scale(1) rotate(-40deg);
  }
}

.imagem img.papel {
  width: 100%;
  max-width: 60px;
  right: -30px;
  bottom: 0;
  z-index: 1;
  animation: papel 10s linear infinite;
}

@keyframes papel {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1.05) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.imagem img.tesoura {
  width: 100%;
  max-width: 60px;
  left: -20px;
  bottom: -10px;
  transform: rotate(-50deg);
  z-index: 1;
  animation: tesoura 10s linear infinite;
}

@keyframes tesoura {
  0% {
    transform: scale(1) rotate(-50deg);
  }
  50% {
    transform: scale(1.05) rotate(-55deg);
  }
  100% {
    transform: scale(1) rotate(-50deg);
  }
}

.imagem img.brilho {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  left: -70px;
  transform: rotate(10deg);
  z-index: 2;
  animation: brilho 10s linear infinite;
}

@keyframes brilho {
  0% {
    transform: scale(1) rotate(10deg);
  }
  50% {
    transform: scale(1.4) rotate(20deg);
  }
  100% {
    transform: scale(1) rotate(10deg);
  }
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 40px 20px 40px 50px;
  z-index: 2;
}

.texto .tag {
  display: flex;
  align-items: center;
}

.texto .tag svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}
.texto .tag p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}
.texto h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  max-width: 200px;
}

.sombra {
  width: 100%;
  height: 50%;
  background: linear-gradient(90deg, var(--cor-escuro-1) 30%, transparent);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1) 30%, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.botoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.botoes .moedas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-3);
}

.botoes .moedas.pendente button {
  opacity: 0.4;
  pointer-events: none;
}

.botoes .moedas.pendente .atual {
  opacity: 0.4;
  pointer-events: none;
}

.botoes .moedas button.quantidade {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  background-color: transparent;
  transition: all 0.3s;
}

.botoes .moedas button.quantidade svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.botoes .moedas button.quantidade:hover {
  opacity: 0.8;
}

.botoes .moedas button.quantidade:hover svg {
  transform: scale(1.2);
}

.botoes .moedas .atual {
  display: flex;
  align-items: center;
  justify-content: center;
}

.botoes .moedas .atual img {
  width: 100%;
  max-width: 35px;
  margin: 0 10px 0 0;
}

.botoes .moedas .atual h3 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
}

.botoes .total {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: var(--cor-escuro-3);
  border: 2px solid var(--cor-escuro-3);
  padding: 15px 0;
  transition: all 0.3s;
}

.botoes .total.pendente {
  pointer-events: none;
}

.botoes .total:hover {
  background-color: var(--cor-escuro-4);
}

.botoes .total p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
@media screen and (max-width: 1000px) {
}
</style>
