<template>
  <div id="rankingXp">
    <SectionTopo />
    <SectionLista />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStoreRanking } from '@stores'
import SectionTopo from '@components/app/rankingXp/partials/SectionTopo.vue'
import SectionLista from '@components/app/rankingXp/partials/SectionLista.vue'

const storeRanking = useStoreRanking()

function carregarUsuarios() {
  storeRanking.receberTodos('xp', 100)
}

onMounted(() => {
  carregarUsuarios()
})
</script>

<style scoped>
#rankingXp {
  animation: fadeIn 0.4s linear;
}
</style>
