<template>
  <div id="saque">
    <SectionTopo />
    <SectionLista />
    <ModalSacar />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStoreSaque } from '@stores'
import SectionTopo from '@components/app/saque/partials/SectionTopo.vue'
import SectionLista from '@components/app/saque/partials/SectionLista.vue'
import ModalSacar from '@components/app/saque/modals/Sacar.vue'

const storeSaque = useStoreSaque()
const emitter = inject('emitter')

function carregarSaques() {
  storeSaque.receberTodos()
}

onMounted(() => {
  carregarSaques()
  emitter.on('atualizarSaques', carregarSaques)
})
</script>

<style scoped>
#saque {
  animation: fadeIn 0.4s linear;
}
</style>
