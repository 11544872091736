<template>
  <Modal nome="editarPix">
    <h3>Editar Pix</h3>
    <p>Altere as informações do seu PIX para solicitar o saque das suas moedas</p>
    <div class="input">
      <label>Tipo de chave</label>
      <select v-model="state.tipo">
        <option value="aleatoria">Aleatória</option>
        <option value="telefone">Telefone</option>
        <option value="email">E-mail</option>
        <option value="cpf">CPF</option>
      </select>
      <Svgs nome="setinha" />
    </div>
    <div class="input" v-if="state.tipo === 'aleatoria'">
      <label>Chave Pix Aleatória</label>
      <input type="text" spellcheck="false" placeholder="Cole aqui sua chave" v-model="state.codigo" />
    </div>
    <div class="input" v-if="state.tipo === 'cpf'">
      <label>Chave Pix CPF</label>
      <input type="tel" spellcheck="false" placeholder="000.000.000-00" v-model="state.codigo" v-mask="`###.###.###-##`" />
    </div>
    <div class="input" v-if="state.tipo === 'email'">
      <label>Chave Pix E-mail</label>
      <input type="email" spellcheck="false" placeholder="email@email.com" v-model="state.codigo" />
    </div>
    <div class="input" v-if="state.tipo === 'telefone'">
      <label>Chave Pix Telefone</label>
      <input type="tel" spellcheck="false" placeholder="(00) 00000-0000" v-model="state.codigo" v-mask="mascaraAtual" />
    </div>
    <button @click="salvar()" :disabled="state.loading">
      <span></span>
      Salvar meu PIX
    </button>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted, computed } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const state = reactive({
  tipo: '',
  codigo: '',
  loading: false
})

async function salvar() {
  state.loading = true

  const payload = {
    pix: {
      tipo: state.tipo.toLowerCase().replace(/-/g, ''),
      codigo: state.codigo
    }
  }

  if (state.tipo || state.codigo) await storePerfil.alterarDados(payload)

  await storePerfil.receberPerfilUsuario()
  state.loading = false
  emitter.emit('atualizarPerfil')
  storeModal.fecharModal('editarPix')
}

const mascaraAtual = computed(() => {
  const digitos = state.codigo ? state.codigo.replace(/\D/g, '') : ''
  return digitos.length <= 10 ? '(##) ####-####' : '(##) #####-####'
})

async function preencherInputs() {
  await storePerfil.receberPerfilUsuario()
  state.tipo = storePerfil.usuario?.pix?.tipo
  state.codigo = storePerfil.usuario?.pix?.codigo
}

function limparCampos() {
  state.codigo = ''
  state.tipo = ''
}

onMounted(() => {
  emitter.on('carregarCampos', preencherInputs)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 0 0 30px 0;
}

.input {
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}

.input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-escuro-3);
  width: 100%;
  transition: all 0.3s;
}

.input input:focus {
  border: 2px solid var(--cor-azul);
}

.input svg {
  position: absolute;
  width: 12px;
  min-width: 12px;
  fill: var(--cor-escuro-4);
  transform: rotate(90deg);
  right: 20px;
  bottom: 20px;
  pointer-events: none;
}

.input select {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-escuro-3);
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  transition: all 0.3s;
}

.input select option {
  font-family: var(--regular);
  font-size: var(--f2);
  background-color: var(--cor-escuro-1);
  color: var(--cor-branco);
}

.input select:focus {
  border: 2px solid var(--cor-azul);
}

.input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: -6px;
  left: 7px;
  background-color: var(--cor-escuro-1);
  padding: 0 10px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(0deg, #7e0171, #ff6a00);
  background: -webkit-linear-gradient(0deg, #7e0171, #ff6a00);
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

button:hover {
  filter: brightness(120%);
}

button:disabled {
  color: transparent;
  pointer-events: none;
}

button:disabled span {
  opacity: 1;
  visibility: visible;
}

button span {
  position: absolute;
  width: 15px;
  height: 15px;
  border: 3px solid var(--cor-branco);
  border-left: 3px solid transparent;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation: rodando 0.5s linear infinite;
  transition: all 0.3s;
}

@keyframes rodando {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
</style>
