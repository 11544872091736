<template>
  <div id="inicio">
    <SectionTopo />
    <SectionJogar />
    <SectionRankingXpTopo />
    <SectionTutorial />
    <SectionRegras />
    <ModalJogar />
    <ModalFree />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStoreRanking } from '@stores'
import SectionTopo from '@components/app/inicio/partials/SectionTopo.vue'
import SectionJogar from '@components/app/inicio/partials/SectionJogar.vue'
import SectionTutorial from '@components/app/inicio/partials/SectionTutorial.vue'
import SectionRegras from '@components/app/inicio/partials/SectionRegras.vue'
import SectionRankingXpTopo from '@components/app/rankingXp/partials/SectionTopo.vue'
import ModalJogar from '@components/app/inicio/modals/Jogar.vue'
import ModalFree from '@components/app/inicio/modals/Free.vue'

const storeRanking = useStoreRanking()

function carregarUsuarios() {
  storeRanking.receberTodos('xp', 100)
}

onMounted(() => {
  carregarUsuarios()
})
</script>

<style scoped>
#inicio {
  animation: fadeIn 0.4s linear;
}
</style>
