import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiRanking from '@/api/jokenplay/api-ranking'

export const useStoreRanking = defineStore('storeRanking', {
  state: () => {
    return {
      usuarios: null
    }
  },
  actions: {
    async receberTodos(ordenar, limite) {
      try {
        const resp = await apiRanking.receberTodos(ordenar, limite)
        this.usuarios = resp
        return true
      } catch (error) {
        this.usuarios = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
