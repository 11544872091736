<template>
  <section class="comprar">
    <!-- Com moedas -->
    <div class="moedas um">
      <div class="imagem">
        <img src="https://arquivos.jokenplay.com/imagens/texto-25.png" class="logo" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-laranjas.png" class="moeda" />
      </div>
      <div class="texto">
        <p>Liberação instantânea</p>
        <div class="nome">
          <h3>Pacote 25</h3>
          <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
          <h3>Moedas</h3>
        </div>
        <button @click="abrirModalEdicao('comprar', '25')">
          <p>Comprar</p>
          <h4>R$ 25,00</h4>
        </button>
      </div>
    </div>
    <!-- Com moedas -->
    <div class="moedas dois">
      <div class="imagem">
        <img src="https://arquivos.jokenplay.com/imagens/texto-50.png" class="logo" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-monte.png" class="moeda" />
      </div>
      <div class="texto">
        <p>Liberação instantânea</p>
        <div class="nome">
          <h3>Pacote 50</h3>
          <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
          <h3>Moedas</h3>
        </div>
        <button @click="abrirModalEdicao('comprar', '50')">
          <p>Comprar</p>
          <h4>R$ 50,00</h4>
        </button>
      </div>
    </div>
    <!-- Com moedas -->
    <div class="moedas tres">
      <div class="imagem">
        <img src="https://arquivos.jokenplay.com/imagens/texto-100.png" class="logo" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-punhado.png" class="moeda" />
      </div>
      <div class="texto">
        <p>Liberação instantânea</p>
        <div class="nome">
          <h3>Pacote 100</h3>
          <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
          <h3>Moedas</h3>
        </div>
        <button @click="abrirModalEdicao('comprar', '100')">
          <p>Comprar</p>
          <h4>R$ 100,00</h4>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'

const storeModal = useStoreModal()

function abrirModalEdicao(nome, id) {
  storeModal.abrirModalEdicao(nome, id)
}
</script>

<style scoped>
section.comprar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.moedas {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-2);
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.moedas .imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 120px;
  border-radius: 10px;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.moedas .imagem img.logo {
  position: absolute;
  z-index: 2;
  animation: brilhando 3s linear infinite;
}

.moedas.um .imagem img.logo {
  width: 70px;
  left: 13px;
  top: 20px;
}

.moedas.dois .imagem img.logo {
  width: 80px;
  left: 8px;
  top: 20px;
}

.moedas.tres .imagem img.logo {
  width: 100px;
  left: -5px;
  top: 20px;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

.moedas .imagem img.moeda {
  position: absolute;
  z-index: 1;
  animation: mexendo 10s linear infinite;
}

.moedas.um .imagem img.moeda {
  width: 110px;
}

.moedas.dois .imagem img.moeda {
  width: 110px;
  top: 20px;
}

.moedas.tres .imagem img.moeda {
  width: 110px;
  top: -10px;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.moedas .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 30px;
}

.moedas .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.moedas .texto .nome {
  display: flex;
  align-items: center;
  margin: 15px 0 15px 0;
}

.moedas .texto .nome img {
  width: 100%;
  max-width: 20px;
  margin: 0 5px;
}

.moedas .texto .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.moedas .texto button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: var(--cor-verde);
  border-radius: 50px;
  transition: all 0.3s;
}

.moedas .texto button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

.moedas .texto button h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  opacity: 0.7;
}

.moedas .texto button:hover {
  background-color: var(--cor-escuro-3);
  transform: scale(1.05);
}
</style>
