<template>
  <section class="topo">
    <button class="cancelar" @click="router.push('/inicio')">Cancelar</button>
    <button class="volume" :class="{ ativo: state.somAtivo }" @click="reproduzir()">
      <Svgs nome="som" />
      <span></span>
    </button>
    <audio ref="audioRef" loop>
      <source src="https://arquivos.jokenplay.com/sons/musica-fundo-1.mp3" type="audio/mp3" />
      Seu navegador não suporta o elemento de áudio.
    </audio>
    <div class="info">
      <div class="tag">
        <Svgs nome="alunos" />
        <p>{{ storeGame.usuariosOnline }} jogando agora</p>
      </div>
      <h2>Procurando seu oponente...</h2>
    </div>
    <div class="imagem">
      <img src="https://arquivos.jokenplay.com/imagens/texto-free.png" class="logo" />
      <img src="https://arquivos.jokenplay.com/imagens/xp-fundo.png" class="moeda" />
    </div>
    <div class="batalha">
      <p>Batalha 1x1</p>
      <h3>Jogo Grátis</h3>
    </div>
    <div class="quadro">
      <h3>Ganhe 5 de experiência e suba no Ranking ao ser o campeão</h3>
      <div class="itens">
        <div class="tag">
          <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
          <p>5</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreGame } from '@stores'
import Svgs from '@svgs'

const router = useRouter()
const audioRef = ref(null)
const storeGame = useStoreGame()

const state = reactive({
  somAtivo: false
})

function reproduzir() {
  if (state.somAtivo) {
    audioRef.value.pause()
    state.somAtivo = false
  } else {
    audioRef.value.play()
    state.somAtivo = true
  }
}
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 20px;
}

button.volume {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: transparent;
}

button.volume.ativo span {
  opacity: 0;
  visibility: hidden;
}

button.volume.ativo svg {
  transform: scale(1);
  animation: mexendoVolume 2s linear infinite;
}

@keyframes mexendoVolume {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
  }
}

button.volume svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
  transform: scale(0.9);
  transition: all 0.3s;
}

button.volume span {
  width: 2px;
  height: 30px;
  background-color: var(--cor-branco);
  transform: rotate(45deg);
  position: absolute;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

button.cancelar {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-vermelho);
  padding: 10px 25px;
  border-radius: 50px;
}

button.cancelar:hover {
  transform: scale(1.05);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px 0 30px 0;
}

.info .tag {
  display: flex;
  align-items: center;
}

.info .tag svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}
.info .tag p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}
.info h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 5px 0 0 0;
  width: 100%;
}

.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 180px;
  border-radius: 10px;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.imagem img.logo {
  width: 120px;
  position: absolute;
  z-index: 2;
  animation: brilhando 1s linear infinite;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

.imagem img.moeda {
  width: 230px;
  left: -40px;
  position: absolute;
  z-index: 1;
  animation: mexendo 5s linear infinite;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.batalha {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px 0 0 0;
}

.batalha p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}
.batalha h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 20px;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-3);
  border-radius: 10px;
  margin: 30px 0 0 0;
  width: 100%;
  max-width: 320px;
}

.quadro h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 220px;
}

.quadro .itens {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}

.quadro .itens img.x {
  width: 100%;
  max-width: 50px;
  margin: 0 0 0 15px;
}

.quadro .itens .tag {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #570454;
}

.quadro .itens .tag p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .itens .tag img {
  width: 100%;
  max-width: 20px;
  margin: 0 10px 0 0;
}
@media screen and (max-width: 1000px) {
}
</style>
