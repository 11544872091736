<template>
  <section class="topo"></section>
</template>

<script setup></script>

<style scoped>
section.topo {
  display: flex;
}
@media screen and (max-width: 1000px) {
}
</style>
