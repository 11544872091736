<template>
  <div id="jogar">
    <SectionCarregando />
    <SectionPlacar />
    <SectionJogar />
    <SectionVencedor />
    <ButtonMusica />
  </div>
</template>

<script setup>
import SectionCarregando from '@components/app/free/partials/SectionCarregando.vue'
import SectionPlacar from '@components/app/free/partials/SectionPlacar.vue'
import SectionJogar from '@components/app/free/partials/SectionJogar.vue'
import SectionVencedor from '@components/app/free/partials/SectionVencedor.vue'
import ButtonMusica from '@components/app/free/partials/ButtonMusica.vue'
import { onMounted, inject, onBeforeUnmount } from 'vue'
import { useStoreGame } from '@stores'

const emitter = inject('emitter')
const storeGame = useStoreGame()

function carregar() {
  storeGame.carregar = true
}

function carregado() {
  storeGame.carregar = false
}

// Bloquear F5 (linux e windows) e COMMAND + R (para mac)
window.addEventListener('keydown', function (event) {
  if (event.key === 'F5' || (event.ctrlKey && event.key === 'r')) {
    event.preventDefault()
  }
})

// Pergunta se o usuário deseja realmente sair
function handleBeforeUnload(event) {
  event.preventDefault()
  event.returnValue = ''
}

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
})

onMounted(() => {
  carregar()
  setTimeout(() => {
    carregado()
  }, 2500)
  emitter.on('carregarJogo', carregar)
  emitter.on('renderizarJogo', carregado)
  window.addEventListener('beforeunload', handleBeforeUnload)
})
</script>

<style scoped>
#jogar {
  display: flex;
}

@media screen and (max-width: 1000px) {
}
</style>
