<template>
  <section class="carregando" :class="{ ativo: storeGame.carregar }">
    <img src="https://arquivos.jokenplay.com/imagens/logo-joken.png" class="logo" />
    <p>Pedra, papel ou tesoura...</p>
    <div class="fundo"></div>
  </section>
</template>

<script setup>
import { useStoreGame } from '@stores'

const storeGame = useStoreGame()
</script>

<style scoped>
section.carregando {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 10;
}

section.carregando.ativo {
  pointer-events: all;
}

section.carregando.ativo .fundo {
  opacity: 0.8;
  visibility: visible;
}

.fundo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100dvh;
  background-color: var(--cor-escuro-1);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.3s;
}

section.carregando.ativo p {
  opacity: 1;
  visibility: visible;
  margin: 120px 0 0 0;
}

p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 100px 0 0 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

section.carregando.ativo img.logo {
  max-width: 300px;
  animation: zoomImg 2s linear infinite;
}

@keyframes zoomImg {
  0% {
    max-width: 300px;
    filter: brightness(100%);
  }
  50% {
    max-width: 330px;
    filter: brightness(120%);
  }
  100% {
    max-width: 300px;
    filter: brightness(100%);
  }
}

img.logo {
  width: 100%;
  max-width: 110px;
  position: fixed;
  top: calc(50% - 4dvh);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.3s;
  animation: logo 1s linear infinite;
}

@keyframes logo {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}
@media screen and (max-width: 1000px) {
}
</style>
