import { api } from '@/api/jokenplay/axios'
import { helperToken } from '@helpers'

const idUsuario = helperToken.idUsuario()
const token = helperToken.receberToken()

export default {
  receberTodos() {
    if (!idUsuario) return

    const method = 'GET'
    const url = `saques/${idUsuario}`

    return api(method, url, null, token)
  },

  adicionar(payload) {
    if (!idUsuario) return

    const method = 'POST'
    const url = `saques/${idUsuario}`

    return api(method, url, payload, token)
  }
}
