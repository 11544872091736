<template>
  <section class="topo">
    <div class="imagem">
      <img src="https://arquivos.jokenplay.com/imagens/mao-papel.png" class="papel" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-pedra.png" class="pedra" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-tesoura.png" class="tesoura" />
      <img src="https://arquivos.jokenplay.com/imagens/brilho.png" class="brilho" />
    </div>
    <div class="texto">
      <div class="tag">
        <Svgs nome="usuarios" />
        <p>{{ jogadoresOnline }} jogando agora</p>
      </div>
      <h2>Compre moedas para jogar suas partidas de Jokenpô</h2>
    </div>
    <div class="sombra"></div>
    <div class="quadro">
      <div class="saldo">
        <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
        <h3>Suas moedas</h3>
        <p>{{ storePerfil.usuario?.saldo?.liberado }} = R$ {{ storePerfil.usuario?.saldo?.liberado }},00</p>
      </div>
      <button @click="sacarAgora()">
        <Svgs nome="saque" />
        <h3>Sacar Agora</h3>
        <p>Mínimo de 50 moedas</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useStorePerfil, useStoreGame } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeGame = useStoreGame()
const router = useRouter()
const emitter = inject('emitter')

const jogadoresOnline = computed(() => storeGame.usuariosOnline)

function sacarAgora() {
  if (storePerfil.usuario?.saldo?.liberado >= 50) {
    router.push('/saque')
  } else {
    const mensagem = 'Continue jogando para chegar ao saldo mínimo'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
  }
}
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('https://arquivos.jokenplay.com/imagens/background-2.png');
  background-size: cover;
  background-position: center;
  margin: 60px 0 0 0;
  padding: 0 0 150px 0;
}

.imagem {
  width: 100px;
  min-width: 100px;
  height: 100px;
  position: relative;
  margin: 0 0 0 50px;
}

.imagem img {
  position: absolute;
}

.imagem img.pedra {
  width: 100%;
  max-width: 60px;
  top: -10px;
  left: 20px;
  transform: rotate(-40deg);
  z-index: 1;
  animation: pedra 10s linear infinite;
}

@keyframes pedra {
  0% {
    transform: scale(1) rotate(-40deg);
  }
  50% {
    transform: scale(1.05) rotate(-35deg);
  }
  100% {
    transform: scale(1) rotate(-40deg);
  }
}

.imagem img.papel {
  width: 100%;
  max-width: 60px;
  right: -30px;
  bottom: 0;
  z-index: 1;
  animation: papel 10s linear infinite;
}

@keyframes papel {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1.05) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.imagem img.tesoura {
  width: 100%;
  max-width: 60px;
  left: -20px;
  bottom: -10px;
  transform: rotate(-50deg);
  z-index: 1;
  animation: tesoura 10s linear infinite;
}

@keyframes tesoura {
  0% {
    transform: scale(1) rotate(-50deg);
  }
  50% {
    transform: scale(1.05) rotate(-55deg);
  }
  100% {
    transform: scale(1) rotate(-50deg);
  }
}

.imagem img.brilho {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  left: -70px;
  transform: rotate(10deg);
  z-index: 2;
  animation: brilho 10s linear infinite;
}

@keyframes brilho {
  0% {
    transform: scale(1) rotate(10deg);
  }
  50% {
    transform: scale(1.4) rotate(20deg);
  }
  100% {
    transform: scale(1) rotate(10deg);
  }
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 40px 20px 40px 50px;
  z-index: 2;
}

.texto .tag {
  display: flex;
  align-items: center;
}

.texto .tag svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}
.texto .tag p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}
.texto h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  max-width: 190px;
}

.sombra {
  width: 100%;
  height: 50%;
  background: linear-gradient(90deg, var(--cor-escuro-1) 30%, transparent);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1) 30%, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background: linear-gradient(90deg, var(--cor-escuro-1), var(--cor-escuro-2));
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1), var(--cor-escuro-2));
  width: calc(100% - 40px);
  padding: 20px;
  bottom: 0;
  z-index: 2;
}

.quadro .saldo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.quadro .saldo img {
  width: 100%;
  max-width: 20px;
  margin: 0 10px 0 0;
}
.quadro .saldo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin-left: auto;
}
.quadro .saldo h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.quadro button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-escuro-3);
  width: 100%;
  padding: 15px;
  border-radius: 50px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.quadro button:hover {
  background-color: var(--cor-escuro-4);
}

.quadro button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
}

.quadro button h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 5px;
}

.quadro button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

@media screen and (max-width: 1000px) {
}
</style>
