import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiAsaas from '@/api/pay/api-asaas'

export const useStoreComprar = defineStore('storeComprar', {
  state: () => {
    return {
      pixGerado: ''
    }
  },
  actions: {
    async gerarCodigoPix(payload) {
      try {
        const resp = await apiAsaas.gerarPix(payload)
        this.pixGerado = resp
        return true
      } catch (error) {
        this.pixGerado = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
