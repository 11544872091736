<template>
  <Modal nome="comprar">
    <div class="imagem" :class="'pacote' + state.pacote">
      <div class="capa">
        <img src="https://arquivos.jokenplay.com/imagens/texto-25.png" class="logo um" />
        <img src="https://arquivos.jokenplay.com/imagens/texto-50.png" class="logo dois" />
        <img src="https://arquivos.jokenplay.com/imagens/texto-100.png" class="logo tres" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-laranjas.png" class="moeda um" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-monte.png" class="moeda dois" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-punhado.png" class="moeda tres" />
      </div>
    </div>
    <div class="titulo">
      <p>Liberação instantânea</p>
      <div class="nome">
        <h3>Pacote {{ state.pacote }}</h3>
        <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
        <h3>moedas</h3>
      </div>
      <div class="input">
        <label>
          CPF
          <span>*</span>
        </label>
        <input type="tel" spellcheck="false" placeholder="000.000.000-00" v-model="state.cpf" v-mask="'###.###.###-##'" />
      </div>
      <button @click="gerarPix(state.pacote)">
        Gerar Pix
        <span>R$ {{ state.pacote }},00</span>
      </button>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Assim que o pagamento for confirmado seu saldo será adicionado. Esse processo leva em média 60 segundos.</p>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Essa compra não é reembolsável. Ao adquirir moedas e comprar skins ou jogar, você perde o direito de reembolso.</p>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Você poderá usar essas moedas no jokenpô e em skins personalizadas. Essas moedas não têm data de validade.</p>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, computed, onMounted, watch, inject } from 'vue'
import { useStoreModal, useStorePerfil, useStoreComprar } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const storeComprar = useStoreComprar()
const dadosModal = computed(() => storeModal.dadosDoModal('comprar'))
const emitter = inject('emitter')

const state = reactive({
  pacote: '',
  cpf: ''
})

function abrirModalEdicao(nome, id) {
  storeModal.fecharModal('comprar')
  storeModal.abrirModalEdicao(nome, id)
}

function validarDados() {
  let mensagem
  if (!state.cpf) {
    mensagem = 'Preencha o CPF para gerar PIX'
  } else {
    return true
  }

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
}

async function gerarPix(quantidade) {
  if (!validarDados()) return

  const nomeUsuario = storePerfil.usuario?.nome
  const emailUsuario = storePerfil.usuario?.email
  const whatsappUsuario = storePerfil.usuario?.contato?.whatsapp
  const cpfUsuario = state.cpf

  const payload = {
    nome: nomeUsuario,
    email: emailUsuario,
    whatsapp: whatsappUsuario,
    cpf: cpfUsuario,
    preco: quantidade
  }

  await storeComprar.gerarCodigoPix(payload)

  abrirModalEdicao('pix', quantidade)
}

async function pegarPacote() {
  if (dadosModal.value.id) {
    state.pacote = dadosModal.value.id
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarPacote()
      }
    },
    { immediate: true }
  )
})

function limparCampos() {
  state.cpf = ''
  state.pacote = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  pointer-events: none;
}

.imagem .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 170px;
  border-radius: 10px;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -70px;
}

.imagem.pacote25 .capa img.logo.um {
  display: flex;
  width: 110px;
  left: 15px;
}

.imagem.pacote50 .capa img.logo.dois {
  display: flex;
  width: 120px;
  left: 5px;
}

.imagem.pacote100 .capa img.logo.tres {
  display: flex;
  width: 140px;
  left: -5px;
}

.imagem .capa img.logo {
  display: none;
  position: absolute;
  z-index: 2;
  animation: brilhando 3s linear infinite;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

.imagem.pacote25 .capa img.moeda.um {
  display: flex;
  width: 160px;
}

.imagem.pacote50 .capa img.moeda.dois {
  display: flex;
  width: 160px;
  left: -20px;
}

.imagem.pacote100 .capa img.moeda.tres {
  display: flex;
  width: 160px;
  left: -20px;
  top: -20px;
}

.imagem .capa img.moeda {
  display: none;
  position: absolute;
  z-index: 1;
  animation: mexendo 10s linear infinite;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 130px 0 0 0;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.titulo .nome {
  display: flex;
  align-items: center;
}

.titulo .nome img {
  width: 100%;
  max-width: 20px;
  margin: 0 5px;
}

.titulo h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background-color: var(--cor-verde);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  border-radius: 50px;
  margin: 15px 0 15px 0;
  transition: all 0.3s;
}

.titulo button span {
  font-family: var(--regular);
  opacity: 0.7;
  margin: 0 0 0 5px;
}

.titulo button:hover {
  background-color: var(--cor-escuro-3);
  transform: scale(1.05);
}

.input {
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
}

.input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid var(--cor-escuro-3);
  width: 100%;
  transition: all 0.3s;
}

.input input:focus {
  border: 2px solid var(--cor-azul);
}

.input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: -6px;
  left: 7px;
  background-color: var(--cor-escuro-1);
  padding: 0 10px;
}

.quadro {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--cor-escuro-2);
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0 0 0;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
}

.quadro svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-amarelo);
  margin: 3px 10px 0 0;
}
</style>
