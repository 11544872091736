import { api } from '@/api/pay/axios'
import { helperToken } from '@helpers'

const idUsuario = helperToken.idUsuario()
const token = helperToken.receberToken()

export default {
  gerarPix(payload) {
    const method = 'POST'
    const url = `asaas/pagar/pix/${idUsuario}`
    const dados = payload

    return api(method, url, dados, token)
  }
}
