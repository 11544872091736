<template>
  <Modal nome="sacar">
    <div class="titulo">
      <p>Confirme o saque</p>
      <div class="nome">
        <h3>Saque de {{ state.quantidade }}</h3>
        <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
        <h3>moedas</h3>
      </div>
      <div class="input">
        <label>Será enviado no pix {{ storePerfil.usuario?.pix?.tipo }}:</label>
        <input type="tel" spellcheck="false" :value="storePerfil.usuario?.pix?.codigo" disabled />
      </div>
      <button @click="sacar()">
        Sim, confirmar saque
        <span>R$ {{ state.quantidade }},00</span>
      </button>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Ao solicitar o saque, ele será enviado no pix acima em até 48 horas.</p>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>O saque só será feito no PIX do proprietário do CPF da conta.</p>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Caso dê algum erro no saque o status na plataforma ficará como Cancelado.</p>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, computed, onMounted, watch, inject } from 'vue'
import { useStoreModal, useStorePerfil, useStoreSaque } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const storeSaque = useStoreSaque()
const dadosModal = computed(() => storeModal.dadosDoModal('sacar'))
const emitter = inject('emitter')

const state = reactive({
  quantidade: ''
})

async function sacar() {
  const payload = {
    valor: state.quantidade
  }

  await storeSaque.adicionar(payload)

  const mensagem = 'Seu saque foi solicitado!'
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem })

  emitter.emit('atualizarSaques')

  storeModal.fecharModal('sacar')
}

async function pegarQuantidade() {
  if (dadosModal.value.id) {
    state.quantidade = dadosModal.value.id
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarQuantidade()
      }
    },
    { immediate: true }
  )
})

function limparCampos() {
  state.quantidade = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  pointer-events: none;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.titulo .nome {
  display: flex;
  align-items: center;
}

.titulo .nome img {
  width: 100%;
  max-width: 20px;
  margin: 0 5px;
}

.titulo h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  background-color: var(--cor-verde);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  border-radius: 50px;
  margin: 15px 0 15px 0;
  transition: all 0.3s;
}

.titulo button span {
  font-family: var(--regular);
  opacity: 0.7;
  margin: 0 0 0 5px;
}

.titulo button:hover {
  background-color: var(--cor-escuro-3);
  transform: scale(1.05);
}

.input {
  position: relative;
  width: 100%;
  margin: 30px 0 0 0;
}

.input input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px dashed var(--cor-escuro-2);
  width: 100%;
  transition: all 0.3s;
}

.input input:focus {
  border: 2px solid var(--cor-azul);
}

.input label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: -6px;
  left: 7px;
  background-color: var(--cor-escuro-1);
  padding: 0 10px;
}

.quadro {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--cor-escuro-2);
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0 0 0;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
}

.quadro svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-amarelo);
  margin: 3px 10px 0 0;
}
</style>
