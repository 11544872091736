import { api } from '@/api/jokenplay/axios'
import { helperToken } from '@helpers'

const idUsuario = helperToken.idUsuario()
const token = helperToken.receberToken()

export default {
  receberTodos(ordenar, limite) {
    if (!idUsuario) return

    const method = 'GET'
    const url = `ranking?ordenar=${ordenar}&limite=${limite}`

    return api(method, url, null, token)
  }
}
