<template>
  <div id="carregando">
    <SectionCarregando />
  </div>
</template>

<script setup>
import SectionCarregando from '@components/autenticacao/carregando/partials/SectionCarregando.vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

onMounted(() => {
  setTimeout(() => {
    router.push('/inicio')
  }, 2000)
})
</script>
