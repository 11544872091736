<template>
  <section class="modal" :class="{ mostrar: modalAberto }" ref="scrollModal">
    <div class="background"></div>
    <div class="conteudo">
      <button class="fechar" @click="fecharModal(nome)">
        <Svgs nome="x" />
      </button>
      <div class="quadro">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { computed, toRefs, ref, watch, onMounted, onUnmounted, inject } from 'vue'
import Svgs from '@svgs'

const props = defineProps({
  nome: {
    type: String,
    required: true
  }
})

const { nome } = toRefs(props)
const emitter = inject('emitter')
const storeModal = useStoreModal()
const modalAberto = computed(() => storeModal.modalAberto(nome.value))

const scrollModal = ref(null)

const resetarScroll = () => {
  if (scrollModal.value) {
    scrollModal.value.scrollTop = 0
  }
}

function fecharModal() {
  storeModal.fecharModal(nome.value)
  emitter.emit('limparCampos')
}

function abrirModal() {
  emitter.emit('carregarCampos')
}

const handleEscape = (event) => {
  if (event.key === 'Escape' && modalAberto.value) {
    fecharModal()
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscape)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleEscape)
})

watch(modalAberto, (novoValor) => {
  if (novoValor) {
    resetarScroll()
    abrirModal()
  }
})
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: scroll;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.mostrar .background {
  opacity: 0.7;
  visibility: visible;
}

section.modal.mostrar .conteudo .quadro {
  transform: scale(1);
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--cor-escuro-1);
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

button.fechar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 13;
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.6s;
}

button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

button.fechar svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 80px 20px 40px 20px;
  width: 100%;
  min-height: 100dvh;
  position: relative;
  z-index: 12;
}

.conteudo .quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-3);
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  transform: scale(0.9);
  transition: all 0.5s;
}
</style>
