<template>
  <div id="procurando">
    <SectionTopo />
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useStoreGame } from '@stores'
import { useRouter } from 'vue-router'
import SectionTopo from '@components/app/procurandoFree/partials/SectionTopo.vue'

const storeGame = useStoreGame()
const router = useRouter()

function comecarJogo() {
  const gratis = true
  storeGame.iniciarBatalha(gratis)
}

watch(
  () => storeGame.salaCriada,
  (novaSala) => {
    if (novaSala) {
      router.push('/free')
    } else {
      console.log('Procurando...')
    }
  },
  { immediate: true }
)

onMounted(() => {
  setTimeout(() => {
    comecarJogo()
  }, 1000)
})
</script>

<style scoped>
#procurando {
  animation: fadeIn 0.4s linear;
}
</style>
