<template>
  <section class="lista">
    <div class="usuario" v-for="saque in storeSaque.saques" :key="saque">
      <div class="icone" :class="saque.status">
        <Svgs :nome="statusIcone(saque.status)" />
        <p>{{ saque.status }}</p>
      </div>
      <div class="nome">
        <h3>Solicitado {{ formatarData(saque.dataRegistro) }}</h3>
        <div class="moedas">
          <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
          <p>{{ saque.valor }} moedas = R$ {{ saque.valor }},00</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { watch } from 'vue'
import { useStoreSaque } from '@stores'
import Svgs from '@svgs'

const storeSaque = useStoreSaque()

function statusIcone(valor) {
  return valor === 'concluido' ? 'check' : valor === 'pendente' ? 'relogio' : valor === 'cancelado' ? 'x' : ''
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

function verificarPendentes() {
  storeSaque.pendente = Array.isArray(storeSaque.saques) && storeSaque.saques.some((saque) => saque.status === 'pendente')
}

watch(
  () => storeSaque.saques,
  () => {
    verificarPendentes()
  },
  { immediate: true }
)
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(90deg, #000000, #15101a);
  background: -webkit-linear-gradient(90deg, #000000, #15101a);
  padding: 20px;
}

.usuario {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid var(--cor-escuro-2);
}

.icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--cor-escuro-4);
  position: relative;
  margin: 0 15px 0 0;
}

.icone.concluido {
  background: linear-gradient(40deg, #2fb646, #15101a);
  background: -webkit-linear-gradient(40deg, #2fb646, #15101a);
}

.icone.cancelado {
  background: linear-gradient(40deg, #b62f4a, #15101a);
  background: -webkit-linear-gradient(40deg, #b62f4a, #15101a);
}

.icone.pendente {
  background: linear-gradient(40deg, #2f65b6, #15101a);
  background: -webkit-linear-gradient(40deg, #2f65b6, #15101a);
}

.icone p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.icone svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

.usuario .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.usuario .nome .moedas {
  display: flex;
  align-items: center;
}

.usuario .nome .moedas img {
  width: 100%;
  max-width: 15px;
  margin: 0 5px 0 0;
}
.usuario .nome .moedas p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
}
</style>
