<template>
  <section class="topo">
    <!-- Imagem -->
    <div class="imagem">
      <img src="https://arquivos.jokenplay.com/imagens/texto-ranking.png" class="texto" />
      <img src="https://arquivos.jokenplay.com/imagens/brilho.png" class="brilho" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-pedra.png" class="mao um" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-papel.png" class="mao dois" />
      <div class="sombra"></div>
    </div>
    <!-- Podio -->
    <div class="podio">
      <div :class="['usuario', posicaoClasse(index)]" v-for="(usuario, index) in primeirosUsuarios" :key="index">
        <img :src="`https://arquivos.jokenplay.com/imagens/${posicaoClasse(index)}.png`" class="posicao" />
        <div class="foto">
          <div class="img" :style="`background-image: url('${usuario.imagemPerfil || 'https://arquivos.jokenplay.com/imagens/placeholder-perfil.png'}')`"></div>
        </div>
        <h3>{{ usuario.nome }}</h3>
        <div class="moedas">
          <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
          <p>{{ usuario.moedas }}</p>
        </div>
      </div>
    </div>
    <!-- Divisor -->
    <div class="divisor">
      <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" class="moedas um" />
      <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" class="moedas dois" />
      <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" class="moedas tres" />
      <img src="https://arquivos.jokenplay.com/imagens/divisor.png" class="divisao" />
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreRanking } from '@stores'

const storeRanking = useStoreRanking()

const primeirosUsuarios = computed(() => {
  return Array.isArray(storeRanking.usuarios) ? storeRanking.usuarios.slice(0, 3) : []
})

function posicaoClasse(index) {
  return index === 0 ? 'primeiro' : index === 1 ? 'segundo' : index === 2 ? 'terceiro' : ''
}
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  position: relative;
  background-image: url('https://arquivos.jokenplay.com/imagens/background-1.png');
  background-size: cover;
  background-position: center;
}

.imagem img {
  position: absolute;
}

.imagem img.mao.um {
  width: 100%;
  max-width: 70px;
  top: 100px;
  left: 60px;
  transform: rotate(-10deg);
  z-index: 1;
  animation: maoUm 10s linear infinite;
}

@keyframes maoUm {
  0% {
    transform: scale(1) rotate(-10deg);
  }
  50% {
    transform: scale(1.05) rotate(-35deg);
  }
  100% {
    transform: scale(1) rotate(-10deg);
  }
}

.imagem img.mao.dois {
  width: 100%;
  max-width: 80px;
  right: 60px;
  bottom: 65px;
  z-index: 1;
  animation: maoDois 10s linear infinite;
}

@keyframes maoDois {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1.05) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.imagem img.texto {
  width: 220px;
  max-width: 220px;
  min-width: 220px;
  transform: rotate(0deg);
  bottom: 80px;
  z-index: 2;
  animation: texto 10s linear infinite;
}

@keyframes texto {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(-5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.imagem img.brilho {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  transform: rotate(10deg);
  bottom: 40px;
  left: 120px;
  z-index: 2;
  animation: brilho 10s linear infinite;
}

@keyframes brilho {
  0% {
    transform: scale(1) rotate(10deg);
  }
  50% {
    transform: scale(1.4) rotate(20deg);
  }
  100% {
    transform: scale(1) rotate(10deg);
  }
}

.imagem .sombra {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(90deg, var(--cor-escuro-1), transparent);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1), transparent);
}

.podio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  min-height: 230px;
}

.podio .usuario.primeiro {
  position: absolute;
  top: -50px;
}

.podio .usuario.segundo {
  position: absolute;
  left: 0;
  top: -20px;
}

.podio .usuario.terceiro {
  position: absolute;
  right: 0;
  top: 10px;
}

.podio .usuario {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 33.33%;
}

.podio .usuario img.posicao {
  width: 100%;
  max-width: 60px;
}

.podio .usuario .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 10px 0 10px 0;
}

.podio .usuario.primeiro .foto {
  background: linear-gradient(50deg, #62520a, #fff889, #e8b40f);
  background: -webkit-linear-gradient(50deg, #62520a, #fff889, #e8b40f);
}

.podio .usuario.segundo .foto {
  background: linear-gradient(50deg, #4c4c52, #ffffff, #c8c8cf);
  background: -webkit-linear-gradient(50deg, #4c4c52, #ffffff, #c8c8cf);
}

.podio .usuario.terceiro .foto {
  background: linear-gradient(50deg, #442307, #f2a466, #ab6529);
  background: -webkit-linear-gradient(50deg, #442307, #f2a466, #ab6529);
}

.podio .usuario .foto .img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--cor-escuro-2);
  background-size: cover;
  background-position: center;
}

.podio .usuario h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.podio .usuario .moedas {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #af4c01;
  border-radius: 50px;
  padding: 8px 10px;
}

.podio .usuario .moedas img {
  width: 100%;
  max-width: 12px;
  margin: 0 5px 0 0;
}

.podio .usuario .moedas p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.divisor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.divisor img.divisao {
  width: 100%;
  z-index: 2;
}

.divisor img.moedas {
  position: absolute;
}

.divisor img.moedas.um {
  width: 100%;
  max-width: 50px;
  margin: 0 130px 0 0;
  bottom: 60px;
  z-index: 3;
}

.divisor img.moedas.dois {
  width: 100%;
  max-width: 80px;
  margin: 0 0 0 70px;
  bottom: 20px;
  z-index: 3;
}

.divisor img.moedas.tres {
  width: 100%;
  max-width: 100px;
  bottom: 25px;
  margin: 0 40px 0 0;
  z-index: 1;
}

@media screen and (max-width: 1000px) {
}
</style>
