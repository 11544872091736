<template>
  <button class="volume" :class="{ ativo: state.somAtivo }" @click="reproduzir()">
    <Svgs nome="som" />
    <span></span>
  </button>
  <audio ref="audioRef" loop>
    <source src="https://arquivos.jokenplay.com/sons/musica-fundo-1.mp3" type="audio/mp3" />
    Seu navegador não suporta o elemento de áudio.
  </audio>
</template>

<script setup>
import { ref, reactive } from 'vue'
import Svgs from '@svgs'

const audioRef = ref(null)

const state = reactive({
  somAtivo: false
})

function reproduzir() {
  if (state.somAtivo) {
    audioRef.value.pause()
    state.somAtivo = false
  } else {
    audioRef.value.play()
    state.somAtivo = true
  }
}
</script>

<style scoped>
button.volume {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 20;
  background-color: transparent;
}

button.volume.ativo span {
  opacity: 0;
  visibility: hidden;
}

button.volume.ativo svg {
  transform: scale(1);
  animation: mexendoVolume 2s linear infinite;
}

@keyframes mexendoVolume {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
  }
}

button.volume svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
  transform: scale(0.9);
  transition: all 0.3s;
}

button.volume span {
  width: 2px;
  height: 30px;
  background-color: var(--cor-branco);
  transform: rotate(45deg);
  position: absolute;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
@media screen and (max-width: 1000px) {
}
</style>
