<template>
  <section class="lista">
    <div class="usuario" v-for="(usuario, index) in usuariosFiltrados" :key="index">
      <div class="foto">
        <p>{{ index + 4 }}º</p>
        <div class="imagem" :style="`background-image: url('${usuario.imagemPerfil || 'https://arquivos.jokenplay.com/imagens/placeholder-perfil.png'}')`"></div>
      </div>
      <div class="nome">
        <h3>{{ usuario.nome }}</h3>
        <div class="moedas">
          <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
          <p>{{ usuario.moedas }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreRanking } from '@stores'

const storeRanking = useStoreRanking()

const usuariosFiltrados = computed(() => {
  return Array.isArray(storeRanking.usuarios) ? storeRanking.usuarios.slice(3) : []
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(90deg, #000000, #231d2a);
  background: -webkit-linear-gradient(90deg, #000000, #231d2a);
  padding: 0 20px;
}

.usuario {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 30px 0;
}

.foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--cor-escuro-4);
  position: relative;
  margin: 0 15px 0 0;
}

.foto p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  position: absolute;
  top: 0;
  right: 0;
}

.foto .imagem {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.usuario .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.usuario .nome .moedas {
  display: flex;
  align-items: center;
}

.usuario .nome .moedas img {
  width: 100%;
  max-width: 15px;
  margin: 0 5px 0 0;
}
.usuario .nome .moedas p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
}
</style>
