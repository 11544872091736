<template>
  <footer>
    <p>Todos os Direitos Reservados | JokenPlay</p>
    <!-- <div class="botoes">
      <a href="https://termos.jokenplay.com" target="_blank">Termos de Uso</a>
      <a href="https://privacidade.jokenplay.com" target="_blank">Política de Privacidade</a>
    </div> -->
  </footer>
</template>

<script setup></script>

<style scoped>
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 20px;
  border-top: 1px solid var(--cor-escuro-3);
  position: relative;
}

footer p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

footer .botoes {
  padding: 10px 0 0 0;
}

footer .botoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
  background-color: transparent;
  margin: 0 10px;
  transition: all 0.3s;
}

footer .botoes a:hover {
  color: var(--cor-azul-forte-escuro);
}
</style>
