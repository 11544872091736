import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiSaques from '@/api/jokenplay/api-saques'

export const useStoreSaque = defineStore('storeSaque', {
  state: () => {
    return {
      saques: null,
      pendente: false
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiSaques.receberTodos()
        this.saques = resp
        return true
      } catch (error) {
        this.saques = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async adicionar(payload) {
      try {
        const resp = await apiSaques.adicionar(payload)
        return useStoreAlerta().exibirSucessoRequest(resp)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
