<template>
  <Modal nome="free">
    <div class="imagem">
      <img src="https://arquivos.jokenplay.com/imagens/mao-tesoura.png" class="pedra" />
      <img src="https://arquivos.jokenplay.com/imagens/mao-papel.png" class="tesoura" />
      <div class="capa">
        <img src="https://arquivos.jokenplay.com/imagens/texto-free.png" class="logo" />
        <img src="https://arquivos.jokenplay.com/imagens/xp-fundo.png" class="moeda" />
      </div>
    </div>
    <div class="titulo">
      <p>Batalha 1x1</p>
      <h3>Jogue grátis</h3>
      <button @click="router.push('/procurando-free')">
        <div class="icone">
          <Svgs nome="playsolto" />
        </div>
        <h4>JOGAR</h4>
      </button>

      <div class="tag">
        <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
        <h4>Jogue quantas vezes quiser</h4>
      </div>
    </div>
    <div class="ganhos">
      <h3>Você jogará com outro jogador, veja abaixo quanto de XP você pode ganhar</h3>
      <div class="itens">
        <div class="item normal">
          <p>XP</p>
          <p>atual</p>
          <div class="tag">
            <h4>{{ storePerfil.usuario?.xp }}</h4>
            <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
          </div>
        </div>
        <div class="item vencendo">
          <p>Vencendo</p>
          <p>a partida</p>
          <div class="tag">
            <h4>{{ storePerfil.usuario?.xp + 5 }}</h4>
            <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
          </div>
        </div>
        <div class="item perdendo">
          <p>Perdendo</p>
          <p>a partida</p>
          <div class="tag">
            <h4>{{ storePerfil.usuario?.xp }}</h4>
            <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStorePerfil } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const router = useRouter()
const storePerfil = useStorePerfil()
</script>

<style scoped>
.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  pointer-events: none;
}

.imagem img.pedra {
  position: absolute;
  width: 100%;
  max-width: 80px;
  top: -40px;
  left: -20px;
  animation: brilhando 1s linear infinite;
  animation-delay: 2s;
}

.imagem img.tesoura {
  position: absolute;
  width: 100%;
  max-width: 80px;
  top: -40px;
  right: -20px;
  transform: rotate(-60deg);
  animation: brilhando 1s linear infinite;
}

.imagem .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 180px;
  border-radius: 10px;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -70px;
}

.imagem .capa img.logo {
  width: 120px;
  position: absolute;
  z-index: 2;
  animation: brilhando 3s linear infinite;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

.imagem .capa img.moeda {
  width: 220px;
  left: -40px;
  position: absolute;
  z-index: 1;
  animation: mexendo 10s linear infinite;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 140px 0 0 0;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.titulo h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px 0 0;
  background-color: var(--cor-escuro-3);
  border-radius: 50px;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.titulo button:hover {
  background-color: var(--cor-escuro-4);
  transform: scale(1.05);
}

.titulo button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--cor-roxo);
}

.titulo button .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo button.adquirir h4 {
  font-size: var(--f3);
}

.titulo button h4 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 0 0 0 15px;
}

.titulo .tag {
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
}

.titulo .tag img {
  width: 100%;
  max-width: 15px;
  margin: 0 5px 0 0;
}

.titulo .tag h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.ganhos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-escuro-2);
  padding: 30px 20px;
  border-radius: 10px;
  margin: 40px 0 0 0;
}

.ganhos h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  margin: 0 0 20px 0;
}

.ganhos .itens {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.ganhos .itens .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: calc(33.33%);
}

.ganhos .itens .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.ganhos .itens .item .tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 10px 15px;
  margin: 10px 0 0 0;
}

.ganhos .itens .item .tag h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.ganhos .itens .item .tag img {
  width: 100%;
  max-width: 15px;
  margin: 0 0 0 5px;
}

.ganhos .itens .item.normal .tag {
  background-color: var(--cor-escuro-4);
  border: 1px solid var(--cor-escuro-4);
}

.ganhos .itens .item.vencendo .tag {
  background: linear-gradient(0deg, var(--cor-escuro-2), #1b6c41);
  border: 1px solid #58ffa5;
}

.ganhos .itens .item.perdendo .tag {
  background: linear-gradient(0deg, var(--cor-escuro-2), #892733);
  border: 1px solid #ff586c;
}
</style>
