<template>
  <section class="topo">
    <div class="texto">
      <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || ''})`">
        <button @click="abrirInputFile" :disabled="state.loading">
          <Svgs nome="camera" />
          <span></span>
          <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
        </button>
      </div>
      <h2>{{ storePerfil.usuario?.nome }}</h2>
      <p>{{ storePerfil.usuario?.email }}</p>
    </div>
    <div class="sombra"></div>
    <div class="botoes">
      <button class="ranking" @click="router.push('/ranking')">
        <img src="https://arquivos.jokenplay.com/imagens/icone-ranking.png" />
        <div class="info">
          <h3>Ranking</h3>
          <p>Global</p>
        </div>
      </button>
      <button class="xp" @click="router.push('/ranking-xp')">
        <img src="https://arquivos.jokenplay.com/imagens/icone-xp.png" />
        <div class="info">
          <h3>XP</h3>
          <p>{{ storePerfil.usuario?.xp }}</p>
        </div>
      </button>
      <button class="moedas" @click="router.push('/moedas')">
        <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
        <h3>Suas Moedas</h3>
        <p>{{ storePerfil.usuario?.saldo?.liberado }} = R$ {{ storePerfil.usuario?.saldo?.liberado }},00</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import { useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import ApiUpload from '@/api/upload/api-upload.js'

const storePerfil = useStorePerfil()
const router = useRouter()
const inputFile = ref(null)
const emitter = inject('emitter')

const state = reactive({
  loading: false
})

function abrirInputFile() {
  inputFile.value.click()
}

async function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 300
  }

  try {
    const resp = await ApiUpload.uploadImagem(payload)
    state.loading = false

    const imagem = {
      imagemPerfil: resp?.url
    }

    const respostaOk = await storePerfil.alterarDados(imagem)
    if (respostaOk) {
      emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Foto de perfil alterada' })
      emitter.emit('atualizarPerfil')
    }
  } catch (error) {
    state.loading = false
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de perfil' })
  }
}
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('https://arquivos.jokenplay.com/imagens/background-2.png');
  background-size: cover;
  background-position: center;
  margin: 60px 0 0 0;
  padding: 0 0 130px 0;
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 20px 40px 20px;
  z-index: 2;
}

.texto .foto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--cor-escuro-2);
  background-image: url('https://arquivos.jokenplay.com/imagens/placeholder-perfil.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.texto .foto button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  background-color: var(--cor-escuro-4);
  transition: all 0.3s;
}

.texto .foto button:hover {
  background-color: var(--cor-azul);
}

.texto .foto button span {
  width: 15px;
  height: 15px;
  border: 2px solid var(--cor-branco);
  border-top: 2px solid transparent;
  border-radius: 50%;
  position: absolute;
  animation: girando 1s linear infinite;
  opacity: 0;
  visibility: hidden;
}

.texto .foto button:disabled span {
  opacity: 1;
  visibility: visible;
}

.texto .foto button:disabled {
  background-color: var(--cor-azul);
}

.texto .foto button:disabled svg {
  fill: var(--cor-azul);
}

.texto .foto button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
}

.texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.texto h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 10px 0 0 0;
}

.sombra {
  width: 100%;
  height: 50%;
  background: linear-gradient(90deg, var(--cor-escuro-1) 30%, transparent);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1) 30%, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.botoes {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.botoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
}

.botoes button.ranking {
  padding: 15px;
  width: calc(50% - 5px);
  background: linear-gradient(90deg, var(--cor-escuro-1), #6f2f00);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1), #6f2f00);
}

.botoes button.xp {
  padding: 15px;
  width: calc(50% - 5px);
  background: linear-gradient(90deg, var(--cor-escuro-1), #520350);
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1), #520350);
}

.botoes button.moedas {
  padding: 20px;
  width: 100%;
  background: linear-gradient(90deg, var(--cor-escuro-1), var(--cor-escuro-2));
  background: -webkit-linear-gradient(90deg, var(--cor-escuro-1), var(--cor-escuro-2));
  margin: 10px 0 0 0;
}

.botoes button img {
  width: 100%;
  max-width: 30px;
  margin: 0 10px 0 0;
}

.botoes button.moedas img {
  max-width: 20px;
}

.botoes button .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botoes button.moedas {
  justify-content: space-between;
}

.botoes button.moedas h3 {
  font-size: var(--f2);
  margin: 0;
}

.botoes button.moedas p {
  font-size: var(--f2);
  margin-left: auto;
}

.botoes button h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.botoes button p {
  font-family: var(--regular);
  font-size: var(--f0);
  color: var(--cor-cinza);
}
@media screen and (max-width: 1000px) {
}
</style>
