<template>
  <section class="opcoes">
    <h2>Ações da sua conta</h2>
    <button @click="abrirModalPix()">
      <div class="icone">
        <Svgs nome="pix" />
      </div>
      <div class="texto">
        <h3>Editar PIX</h3>
        <p>Altere seu pix para saque</p>
      </div>
    </button>
    <button @click="abrirModalEditar()">
      <div class="icone">
        <Svgs nome="editar" />
      </div>
      <div class="texto">
        <h3>Editar perfil</h3>
        <p>Altere as informações do seu perfil</p>
      </div>
    </button>
    <button @click="abrirModalSenha()">
      <div class="icone">
        <Svgs nome="cadeado" />
      </div>
      <div class="texto">
        <h3>Alterar senha</h3>
        <p>Crie uma nova senha para sua conta</p>
      </div>
    </button>
    <button @click="sairDaConta()">
      <div class="icone">
        <Svgs nome="sair" />
      </div>
      <div class="texto">
        <h3>Sair da conta</h3>
        <p>Saia de sua conta Jokenplay</p>
      </div>
    </button>
  </section>
</template>

<script setup>
import { inject } from 'vue'
import { useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const emitter = inject('emitter')

function abrirModalSenha() {
  storeModal.abrirModal('alterarSenha')
}

function abrirModalPix() {
  storeModal.abrirModal('editarPix')
}

function abrirModalEditar() {
  storeModal.abrirModal('editarPerfil')
}

function sairDaConta() {
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Você saiu da JokenPlay' })
  localStorage.removeItem('jokenplay-app-access-token')
  window.location.assign('/')
}
</script>

<style scoped>
section.opcoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 20px 0;
}

button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-escuro-2);
  border-radius: 10px;
  width: 100%;
  margin: 0 0 15px 0;
  transition: all 0.3s;
}

button:hover {
  background-color: var(--cor-escuro-3);
}

button:hover .icone {
  background-color: var(--cor-escuro-4);
}

button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: var(--cor-escuro-3);
  transition: all 0.3s;
}

button .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

button .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
}

button .texto h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}
@media screen and (max-width: 1000px) {
}
</style>
