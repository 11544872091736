<template>
  <section class="regras">
    <div class="quadro">
      <div class="titulo">
        <h2>Regras da JokenPlay</h2>
        <p>Para mantermos o jogo justo, organizado e divertido, as regras abaixo devem ser seguidas</p>
      </div>
      <div class="itens">
        <!-- Regra -->
        <div class="item" v-for="item in state.itens" :key="item">
          <Svgs :nome="item.icone" />
          <p>{{ item.texto }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import Svgs from '@svgs'

const state = reactive({
  itens: [
    {
      icone: 'maioridade',
      texto: 'Jogo com moedas somente para maiores de 18'
    },
    {
      icone: 'saque',
      texto: 'Saque com no mínimo 50 moedas de saldo'
    },
    {
      icone: 'moedas',
      texto: 'O menor depósito é de 25 moedas'
    },
    {
      icone: 'trofeu',
      texto: 'Ganhe 1.9x ao ser o vencedor do 1x1'
    },
    {
      icone: 'triste',
      texto: 'Ao perder o 1x1 você perderá as moedas da rodada'
    },
    {
      icone: 'cadeado-aberto',
      texto: 'Jogo aberto 24h por dia'
    },
    {
      icone: 'pix',
      texto: 'Depósitos só serão feitos para PIX do mesmo CPF'
    },
    {
      icone: 'usuario-check',
      texto: 'Obrigatório o cadastro completo para ter moedas'
    },
    {
      icone: 'brasil',
      texto: 'Só são aceitos jogadores do Brasil e com CPF'
    },
    {
      icone: 'banir',
      texto: 'Em caso de ações suspeitas será banido'
    },
    {
      icone: 'coluna-moedas',
      texto: 'Moedas serão adicionadas após o pagamento do pix'
    },
    {
      icone: 'infinito',
      texto: 'Saque máximo de moedas ilimitado'
    }
  ]
})
</script>

<style scoped>
section.regras {
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px 20px;
  width: 100%;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-2);
  padding: 30px 20px;
  width: 100%;
  border-radius: 10px;
}

.quadro .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.quadro .titulo h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.quadro .itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0 0 0;
}

.quadro .itens .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(33.33% - 5px);
  background-color: var(--cor-escuro-2);
  border-radius: 10px;
  padding: 13px 8px;
  margin: 10px 0 0 0;
}

.quadro .itens .item svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
  margin: 0 0 10px 0;
}

.quadro .itens .item p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco);
  line-height: 1.5;
}
</style>
