<template>
  <section class="tutorial">
    <div class="quadro">
      <h2>Como jogar com moedas?</h2>
      <div class="etapas">
        <!-- Etapa -->
        <div class="etapa">
          <div class="numero">
            <p>1</p>
          </div>
          <div class="linha"></div>
          <h3 @click="router.push('/moedas')">
            Tenha saldo em moedas para jogar o 1x1. Adicione
            <span>clicando aqui.</span>
          </h3>
          <img src="https://arquivos.jokenplay.com/imagens/moedas-juntas.png" />
        </div>
        <!-- Etapa -->
        <div class="etapa centro">
          <div class="numero">
            <p>2</p>
          </div>
          <div class="linha"></div>
          <h3>Cada partida custa 5 moedas para entrar contra outro jogador</h3>
          <img src="https://arquivos.jokenplay.com/imagens/icone-moeda.png" />
        </div>
        <!-- Etapa -->
        <div class="etapa">
          <div class="numero">
            <p>3</p>
          </div>
          <div class="linha"></div>
          <h3>Ao ganhar você recupera as 5 moedas e ganha + 4 moedas</h3>
          <img src="https://arquivos.jokenplay.com/imagens/texto-1-9x.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<style scoped>
section.tutorial {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  width: 100%;
  background: linear-gradient(90deg, transparent, #231d2a);
  background: -webkit-linear-gradient(90deg, transparent, #231d2a);
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--cor-escuro-1);
  border: 2px solid var(--cor-escuro-2);
  padding: 30px 20px;
  width: 100%;
  border-radius: 10px;
}

.quadro h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .etapas {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 0 0;
}

.quadro .etapas .etapa {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
}

.quadro .etapas .etapa.centro {
  padding: 0 10px;
}

.quadro .etapas .etapa .numero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-2);
  border-radius: 50%;
}

.quadro .etapas .etapa .numero p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .etapas .etapa .linha {
  width: 2px;
  height: 30px;
  background-color: var(--cor-escuro-4);
  margin: 15px 0;
  border-radius: 10px;
}

.quadro .etapas .etapa h3 {
  font-family: var(--regular);
  font-size: var(--f0);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 90px;
}

.quadro .etapas .etapa h3 span {
  text-decoration: underline;
  color: var(--cor-amarelo);
  font-family: var(--bold);
}

.quadro .etapas .etapa img {
  width: 100%;
  max-width: 30px;
  margin: 15px 0 0 0;
}
</style>
