<template>
  <section class="jogar" :class="{ oculto: storeGame.carregar }">
    <!-- Oponente -->
    <div class="oponente">
      <!-- Escolhendo -->
      <div class="escolhendo" :class="{ mostrar: state.escolhendo }">
        <div class="foto" :style="`background-image: url('${imagemOponente}')`"></div>
        <p>{{ jogadorOponente }} está escolhendo entre pedra, papel ou tesoura...</p>
      </div>
      <!-- Escolhido -->
      <div class="escolhido" :class="{ mostrar: !state.escolhendo, acertou: state.vitoriaOponente === true, errou: state.vitoriaOponente === false }">
        <div class="foto" :style="`background-image: url('${imagemOponente}')`"></div>

        <img v-if="escolhaOponente" class="mao" :src="`https://arquivos.jokenplay.com/imagens/mao-${escolhaOponente}.png`" />
        <img v-if="escolhaOponente" class="texto" :src="`https://arquivos.jokenplay.com/imagens/texto-${escolhaOponente}.png`" />
      </div>
    </div>

    <!-- Jogador -->
    <div class="jogador">
      <!-- Escolhendo -->
      <div class="escolhendo" :class="{ mostrar: state.escolhendo }">
        <div class="foto" :style="`background-image: url('${imagemProprio}')`"></div>
        <p v-if="state.maoSelecionada === ''">Escolha abaixo se vai jogar pedra, papel ou tesoura para vencer o {{ jogadorOponente }}!</p>
        <p v-else>Você escolheu {{ state.maoSelecionada }}, espere {{ jogadorOponente }} escolher para ver quem ganhou!</p>
        <div class="opcoes" :class="{ selecionado: state.selecionado }">
          <button class="um" @click="escolhaFeita('pedra')" :class="{ ativo: state.maoSelecionada === 'pedra' }">
            <img src="https://arquivos.jokenplay.com/imagens/mao-pedra.png" class="mao" />
            <img src="https://arquivos.jokenplay.com/imagens/texto-pedra.png" class="texto" />
          </button>
          <button class="dois" @click="escolhaFeita('papel')" :class="{ ativo: state.maoSelecionada === 'papel' }">
            <img src="https://arquivos.jokenplay.com/imagens/mao-papel.png" class="mao" />
            <img src="https://arquivos.jokenplay.com/imagens/texto-papel.png" class="texto" />
          </button>
          <button class="tres" @click="escolhaFeita('tesoura')" :class="{ ativo: state.maoSelecionada === 'tesoura' }">
            <img src="https://arquivos.jokenplay.com/imagens/mao-tesoura.png" class="mao" />
            <img src="https://arquivos.jokenplay.com/imagens/texto-tesoura.png" class="texto" />
          </button>
        </div>
      </div>
      <!-- Escolhido -->
      <div class="escolhido" :class="{ mostrar: !state.escolhendo, acertou: state.vitoriaProprio === true, errou: state.vitoriaProprio === false }">
        <div class="foto dois" :style="`background-image: url('${imagemProprio}')`"></div>

        <img v-if="escolhaPropria" class="mao" :src="`https://arquivos.jokenplay.com/imagens/mao-${escolhaPropria}.png`" />
        <img v-if="escolhaPropria" class="texto" :src="`https://arquivos.jokenplay.com/imagens/texto-${escolhaPropria}.png`" />
        <p v-if="state.vitoriaProprio">{{ textoEscolhidoVitoria }}</p>
        <p v-if="!state.vitoriaProprio" :class="{ oculto: state.empatou }">{{ textoEscolhidoDerrota }}</p>
        <p v-if="state.empatou">Empatou!</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, watch, inject, computed, onMounted } from 'vue'
import { useStoreGame, useStorePerfil } from '@stores'

const storeGame = useStoreGame()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

const state = reactive({
  meuUsuario: '',
  maoSelecionada: '',
  selecionado: false,
  escolhendo: true,
  vitoriaOponente: false,
  vitoriaProprio: false,
  empatou: false
})

function identificarMeuUsuario() {
  if (storeGame.salaCriada?.usuario1?.id === storePerfil.usuario?._id) {
    state.meuUsuario = 'usuario1'
  } else {
    state.meuUsuario = 'usuario2'
  }
}

const valoresResultados = computed(() => ({
  resultadoProprio: storeGame.resultadoRodada?.escolhas?.[state.meuUsuario] || '',
  resultadoOponente: storeGame.resultadoRodada?.escolhas?.[state.meuUsuario === 'usuario1' ? 'usuario2' : 'usuario1'] || ''
}))

function atualizarResultadoRodada() {
  const vencedor = storeGame.resultadoRodada?.vencedor

  if (vencedor === '') {
    state.vitoriaProprio = false
    state.vitoriaOponente = false
    empatouRodada()
    return
  }

  if (!vencedor) return

  if (vencedor === state.meuUsuario) {
    state.vitoriaProprio = true
    state.vitoriaOponente = false
    state.empatou = false
  } else {
    state.vitoriaProprio = false
    state.vitoriaOponente = true
    state.empatou = false
  }
}

function empatouRodada() {
  state.escolhendo = false
  state.empatou = true

  setTimeout(() => {
    state.empatou = false
  }, 4000)
}

const jogadorOponente = computed(() => {
  return storeGame.salaCriada?.[state.meuUsuario === 'usuario1' ? 'usuario2' : 'usuario1']?.nome || null
})

const imagemProprio = computed(() => storeGame.salaCriada?.[state.meuUsuario]?.imagem || null)
const imagemOponente = computed(() => storeGame.salaCriada?.[state.meuUsuario === 'usuario1' ? 'usuario2' : 'usuario1']?.imagem || null)
const escolhaOponente = computed(() => valoresResultados.value.resultadoOponente)
const escolhaPropria = computed(() => valoresResultados.value.resultadoProprio)

function escolhaFeita(escolha) {
  storeGame.escolhaFeitaNaBatalha(escolha)
  state.maoSelecionada = escolha
  state.selecionado = true
}

const frasesVitoria = {
  tesoura: ['Você mandou muito bem com essa tesoura!', 'Tesourada de respeito, hein? Parabéns!', 'Tesoura afiada! Dessa vez você se garantiu!', 'Cortou tudo! Quem manda aqui é você!'],
  pedra: ['Essa pedrada foi certeira! Venceu fácil!', 'Pedra na cabeça! Não teve pra ninguém!', 'Pedra invencível! Você arrasou!', 'Pedrada de mestre! Essa vai pro livro de história!'],
  papel: ['Enrolou bonito com esse papel! Ganhou!', 'Papel esperto, você jogou muito bem!', 'Papel campeão, vitória sua!', 'Papel que é papel, sempre vence! Boa!']
}

const textoEscolhidoVitoria = computed(() => {
  return frasesVitoria[state.maoSelecionada]?.[Math.floor(Math.random() * frasesVitoria[state.maoSelecionada].length)] || ''
})

const frasesDerrota = {
  tesoura: ['Tesoura cega, hein? Não deu dessa vez!', 'Cortou errado e se deu mal... Tenta de novo!', 'Essa tesoura não tava afiada, né? Perdeu!', 'Tesoura sem fio, não foi dessa vez!'],
  pedra: ['Pedra mole, não deu! Perdeu!', 'Essa pedra virou areia, hein? Que azar!', 'Pedra? Mais pra pedrinha... Perdeu!', 'Pedrada que nada! Dessa vez você não levou!'],
  papel: ['Papel furado, não deu certo!', 'Me enrolou não, perdi feio!', 'Esse papel tava rasgado, né? Não foi dessa vez!', 'Papel amassado? Não deu certo dessa vez!']
}

const textoEscolhidoDerrota = computed(() => {
  return frasesDerrota[state.maoSelecionada]?.[Math.floor(Math.random() * frasesDerrota[state.maoSelecionada].length)] || ''
})

function mostrarResultado() {
  state.escolhendo = false

  setTimeout(() => {
    state.maoSelecionada = ''
    state.selecionado = false
    state.escolhendo = true
  }, 4000)
}

onMounted(() => {
  identificarMeuUsuario()
})

watch(
  () => storeGame.resultadoRodada,
  (novoResultado) => {
    if (novoResultado) {
      emitter.emit('carregarTela')
      atualizarResultadoRodada()
      mostrarResultado()
    } else {
      emitter.emit('renderizarTela')
    }
  },
  { immediate: true }
)
</script>

<style scoped>
section.jogar {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100dvh;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  transition: all 0.6s;
  transform: scale(1);
}

section.jogar.oculto {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
}

.oponente {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 45dvh;
  max-height: 45dvh;
  width: 100%;
}

.escolhendo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 1s;
}

.escolhendo.mostrar {
  opacity: 1;
  visibility: visible;
}

.escolhido {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: all 1s;
}

.escolhido.mostrar {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.escolhido.acertou {
  background: radial-gradient(circle, #055030, #05503000 60%);
  background: -webkit-radial-gradient(circle, #055030, #05503000 60%);
  background-size: 500px 500px;
  background-position: center;
  background-repeat: no-repeat;
  animation: mexendoAcertou 4s ease-in-out infinite;
}

@keyframes mexendoAcertou {
  0% {
    background-size: 500px 500px;
  }
  50% {
    background-size: 300px 300px;
  }
  100% {
    background-size: 500px 500px;
  }
}

.escolhido.errou {
  background: radial-gradient(circle, #750505, #75050500 60%);
  background: -webkit-radial-gradient(circle, #750505, #75050500 60%);
  background-size: 500px 500px;
  background-position: center;
  background-repeat: no-repeat;
  animation: mexendoAcertou 4s ease-in-out infinite;
  animation-delay: 1s;
}

@keyframes mexendoAcertou {
  0% {
    background-size: 500px 500px;
  }
  50% {
    background-size: 300px 300px;
  }
  100% {
    background-size: 500px 500px;
  }
}

.escolhido p.oculto {
  display: none;
}

.escolhido p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
  line-height: 1.4;
  width: 100%;
  max-width: 200px;
  position: absolute;
  left: 15%;
  top: 20%;
  transform: rotate(-10deg);
}

.escolhido .foto.dois {
  top: 35%;
}

.escolhido .foto {
  position: absolute;
  top: 30%;
  left: 25%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--cor-escuro-2);
  background-image: url('https://arquivos.jokenplay.com/imagens/placeholder-perfil.png');
  background-size: cover;
  background-position: center;
  animation: fotoEscolhido 10s linear infinite;
}

@keyframes fotoEscolhido {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.escolhido.acertou img.mao {
  animation: maoEscolhida 5s ease-in-out infinite;
}

.escolhido img.mao {
  position: absolute;
  left: 40%;
  width: 100%;
  max-width: 150px;
  animation: maoEscolhida 10s ease-in-out infinite;
}

@keyframes maoEscolhida {
  0% {
    transform: rotate(0deg);
    filter: brightness(100%);
  }
  50% {
    transform: rotate(10deg);
    filter: brightness(110%);
  }
  100% {
    transform: rotate(0deg);
    filter: brightness(100%);
  }
}

.escolhido img.texto {
  position: absolute;
  width: 100%;
  max-width: 130px;
  left: 25%;
  bottom: 35%;
  animation: textoEscolhido 15s linear infinite;
}

@keyframes textoEscolhido {
  0% {
    transform: rotate(0deg);
    filter: brightness(100%);
  }
  50% {
    transform: rotate(-5deg);
    filter: brightness(120%);
  }
  100% {
    transform: rotate(0deg);
    filter: brightness(100%);
  }
}

.escolhendo .foto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--cor-escuro-2);
  background-image: url('https://arquivos.jokenplay.com/imagens/placeholder-perfil.png');
  background-size: cover;
  background-position: center;
  margin: 0 0 15px 0;
}

.escolhendo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.jogador {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 55dvh;
  max-height: 55dvh;
  padding: 40px 0 0 0;
  width: 100%;
}

.opcoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 0 0;
}

.opcoes.selecionado {
  pointer-events: none;
}

.opcoes.selecionado button {
  opacity: 0.5;
}

.opcoes.selecionado button.ativo {
  opacity: 1;
}

.opcoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 5px);
  height: 130px;
  min-height: 130px;
  background-color: var(--cor-escuro-2);
  border: 2px solid var(--cor-escuro-2);
  border-radius: 20px;
  position: relative;
  transition: all 0.3s;
}

.opcoes button.ativo {
  background-color: var(--cor-escuro-3);
  border: 2px solid var(--cor-laranja);
}

.opcoes button:hover {
  background-color: var(--cor-escuro-3);
}

.opcoes button.ativo img.mao {
  transform: scale(1.3);
}

.opcoes button.ativo img.texto {
  transform: scale(1.2);
}

.opcoes button img.mao {
  width: 100%;
  max-width: 50px;
  position: absolute;
  transition: all 0.3s;
}

.opcoes button img.texto {
  width: 100%;
  max-width: 60px;
  position: absolute;
  bottom: -10px;
  transition: all 0.3s;
}

.opcoes button.tres img.texto {
  max-width: 85px;
}
</style>
