import { defineStore } from 'pinia'
import { helperToken } from '@helpers'
import { io } from 'socket.io-client'

const idUsuario = helperToken.idUsuario()

export const useStoreGame = defineStore('storeGame', {
  state: () => ({
    socket: null,
    idSala: '',
    usuariosOnline: 0,
    salaCriada: null,
    resultadoRodada: null,
    resultadoBatalha: null,
    batalhaFinalizada: false,
    carregar: false
  }),

  actions: {
    conectar(nome, imagemPerfil) {
      const dados = { idUsuario, nome, imagemPerfil }
      this.socket = io('https://socket-game.jokenplay.com', { query: dados })

      this.eventoUsuariosOnline()
      this.eventoSalaCriada()
      this.eventoMostrarResultadoRodada()
      this.eventoMostrarVencedor()
    },

    eventoUsuariosOnline() {
      this.socket.on('client.sistema.usuariosOnline', (dados) => {
        this.usuariosOnline = dados
      })
    },

    // Iniciar

    iniciarBatalha(gratis = false) {
      this.socket.emit('server.batalha.iniciar', { gratis })
    },

    eventoSalaCriada() {
      this.socket.on('client.batalha.salaCriada', (dados) => {
        this.idSala = dados.idSala
        this.salaCriada = dados
      })
    },

    // Batalha

    escolhaFeitaNaBatalha(escolha) {
      const dados = { escolha, idSala: this.idSala }
      this.socket.emit('server.batalha.escolhaFeita', dados)
    },

    eventoMostrarResultadoRodada() {
      this.socket.on('client.batalha.mostrarResultadoRodada', (dados) => {
        this.resultadoRodada = dados
      })
    },

    eventoMostrarVencedor() {
      this.socket.on('client.batalha.encerrada', (dados) => {
        this.resultadoBatalha = dados
        this.batalhaFinalizada = true
      })
    }
  }
})
