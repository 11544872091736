<template>
  <div id="perfil">
    <SectionTopo />
    <SectionOpcoes />
    <SectionRegras />
    <ModalAlterarSenha />
    <ModalEditarPerfil />
    <ModalEditarPix />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStorePerfil } from '@stores'
import SectionTopo from '@components/app/perfil/partials/SectionTopo.vue'
import SectionOpcoes from '@components/app/perfil/partials/SectionOpcoes.vue'
import SectionRegras from '@components/app/inicio/partials/SectionRegras.vue'
import ModalAlterarSenha from '@components/app/perfil/modals/AlterarSenha.vue'
import ModalEditarPerfil from '@components/app/perfil/modals/EditarPerfil.vue'
import ModalEditarPix from '@components/app/perfil/modals/EditarPix.vue'

const storePerfil = useStorePerfil()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

onMounted(() => {
  carregarPerfil()
  emitter.on('atualizarPerfil', carregarPerfil)
})
</script>

<style scoped>
#perfil {
  animation: fadeIn 0.4s linear;
}
</style>
