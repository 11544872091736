<template>
  <Modal nome="pix">
    <div class="imagem" :class="'pacote' + state.pacote">
      <div class="capa">
        <img src="https://arquivos.jokenplay.com/imagens/texto-25.png" class="logo um" />
        <img src="https://arquivos.jokenplay.com/imagens/texto-50.png" class="logo dois" />
        <img src="https://arquivos.jokenplay.com/imagens/texto-100.png" class="logo tres" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-laranjas.png" class="moeda um" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-monte.png" class="moeda dois" />
        <img src="https://arquivos.jokenplay.com/imagens/moedas-punhado.png" class="moeda tres" />
      </div>
    </div>
    <div class="titulo">
      <p>Liberação instantânea</p>
      <div class="nome">
        <h3>Pacote {{ state.pacote }}</h3>
        <img src="https://arquivos.jokenplay.com/imagens/icone-moedas-3.png" />
        <h3>moedas</h3>
      </div>
      <h4>R$ {{ state.pacote }},00</h4>
      <div class="gerado">
        <h5>{{ storeComprar.pixGerado?.payload }}</h5>
        <button @click="copiarCodigo()">Copiar Pix Copia e Cola</button>
      </div>
      <h6>Expira em {{ tempoExpiracao }}</h6>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Acesse o aplicativo do seu banco e acesse a área de PIX. Procure por Pix Copia e Cola.</p>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Copie o código acima clicando no botão e cole no Pix Copia e Cola do seu banco.</p>
    </div>
    <div class="quadro">
      <Svgs nome="check" />
      <p>Após o pagamento ser confirmado, as 25 moedas serão adicionadas em sua conta.</p>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, computed, onMounted, watch, inject, ref } from 'vue'
import { useStoreModal, useStoreComprar } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const storeModal = useStoreModal()
const storeComprar = useStoreComprar()
const dadosModal = computed(() => storeModal.dadosDoModal('pix'))
const emitter = inject('emitter')
const tempoExpiracao = ref('5:00')
let countdown

const state = reactive({
  pacote: ''
})

async function pegarPacote() {
  if (dadosModal.value.id) {
    state.pacote = dadosModal.value.id
  }
}

function copiarCodigo() {
  const valor = storeComprar.pixGerado?.payload
  navigator.clipboard.writeText(valor)
  const mensagem = 'Código pix copiado!'
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem })
}

function iniciarExpiracao() {
  let tempoRestante = 300

  if (countdown) {
    clearInterval(countdown)
  }

  countdown = setInterval(() => {
    const minutos = Math.floor(tempoRestante / 60)
    const segundos = tempoRestante % 60

    tempoExpiracao.value = `${minutos}:${segundos.toString().padStart(2, '0')}`
    tempoRestante -= 1

    if (tempoRestante < 0) {
      clearInterval(countdown)
    }
  }, 1000)
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarPacote()
        iniciarExpiracao()
      }
    },
    { immediate: true }
  )
})

function limparCampos() {
  state.pacote = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  pointer-events: none;
}

.imagem .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 170px;
  border-radius: 10px;
  background-image: url('https://arquivos.jokenplay.com/imagens/fundo-1x1.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -70px;
}

.imagem.pacote25 .capa img.logo.um {
  display: flex;
  width: 110px;
  left: 15px;
}

.imagem.pacote50 .capa img.logo.dois {
  display: flex;
  width: 120px;
  left: 5px;
}

.imagem.pacote100 .capa img.logo.tres {
  display: flex;
  width: 140px;
  left: -5px;
}

.imagem .capa img.logo {
  display: none;
  position: absolute;
  z-index: 2;
  animation: brilhando 3s linear infinite;
}

@keyframes brilhando {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(120%);
  }
  100% {
    filter: brightness(100%);
  }
}

.imagem.pacote25 .capa img.moeda.um {
  display: flex;
  width: 160px;
}

.imagem.pacote50 .capa img.moeda.dois {
  display: flex;
  width: 160px;
  left: -20px;
}

.imagem.pacote100 .capa img.moeda.tres {
  display: flex;
  width: 160px;
  left: -20px;
  top: -20px;
}

.imagem .capa img.moeda {
  display: none;
  position: absolute;
  z-index: 1;
  animation: mexendo 10s linear infinite;
}

@keyframes mexendo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 130px 0 0 0;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.titulo h4 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-cinza);
  margin: 10px 0 20px 0;
}

.titulo .gerado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-escuro-2);
  padding: 30px 20px;
  border-radius: 10px;
  width: 100%;
}

.titulo .gerado h5 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.4;
  word-break: break-all;
}

.titulo h6 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-vermelho);
  margin: 20px 0 0 0;
}

.titulo img.qrcode {
  width: 100%;
  max-width: 170px;
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.titulo .nome {
  display: flex;
  align-items: center;
}

.titulo .nome img {
  width: 100%;
  max-width: 20px;
  margin: 0 5px;
}

.titulo h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 23px;
  background-color: var(--cor-azul);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  border-radius: 50px;
  margin: 15px 0 0 0;
  transition: all 0.3s;
}

.titulo button span {
  font-family: var(--regular);
  opacity: 0.7;
  margin: 0 0 0 5px;
}

.titulo button:hover {
  background-color: var(--cor-escuro-3);
  transform: scale(1.05);
}

.quadro {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 30px 0 0 0;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
}

.quadro svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-azul);
  margin: 3px 10px 0 0;
}
</style>
